import { Typography, Box } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#663399',
        padding: '10px',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Typography variant="body2">
        Disclaimer: This game was created by a community member. The KWIF team did not build this game and takes no responsibility for its content or functionality. DM the creator if you have any questions: @Tdubs_dev. 
      </Typography>
    </Box>
  );
};

export default Footer;
