import React, { useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import { supabase } from '../supabaseClient';
import {
  AppBar, Toolbar, Typography, IconButton, Drawer,
  List, ListItem, ListItemText, Box, TextField, Button, Modal 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LeaderboardGame from './LeaderBoardGame';
import Footer from './Footer';

const Game = () => {
  const gameContainerRef = useRef(null);
  const [score, setScore] = useState(0);
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pointAlert, setPointAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(!username);
  const [userRank, setUserRank] = useState(null);
  const [totalScoreAll, setTotalScoreAll] = useState(0);
  const [usernameError, setUsernameError] = useState('');
  const canvasRef = useRef(null); // Add ref for the canvas

  // Disable scrolling
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Initialize anonymous authentication on component mount
  useEffect(() => {
    const authenticateAnonymously = async () => {
      if (!userId) {
        const { data, error } = await supabase.auth.signInAnonymously();
        if (error) {
          console.error('Anonymous authentication error:', error.message);
        } else if (data) {
          setUserId(data.user.id);
          localStorage.setItem('userId', data.user.id);
          console.log('Anonymous user signed in with ID:', data.user.id);
        }
      }
    };

    authenticateAnonymously();
  }, [userId]);

  // Fetch or set up the user's data in the leaderboard after the user ID is retrieved
  useEffect(() => {
    const fetchOrCreateUserData = async () => {
      if (userId) {
        const { data: existingEntry, error } = await supabase
          .from('leaderboard')
          .select('username, score')
          .eq('user_id', userId)
          .single();

        if (error && error.code !== 'PGRST116') {
          console.error('Error fetching existing data:', error.message);
        } else if (existingEntry) {
          setUsername(existingEntry.username);
          setScore(existingEntry.score);
          localStorage.setItem('username', existingEntry.username);
          setIsModalOpen(false);
        } else {
          setIsModalOpen(true);
        }
      }
    };
    fetchOrCreateUserData();
  }, [userId]);

  // Only submit the score when both user ID and score are set
  useEffect(() => {
    if (userId && score > 0) {
      submitScoreToSupabase(score);
    }
  }, [userId, score]);

  const fetchRankAndTotalScore = async () => {
    try {
      const { data: rankData } = await supabase
        .from('leaderboard')
        .select('id, username, score')
        .order('score', { ascending: false })
        .neq('score', 0);

      const userRank = rankData.findIndex((entry) => entry.username === username) + 1;
      setUserRank(userRank > 0 ? userRank : '-');

      const { data: totalScoreData } = await supabase
        .from('leaderboard')
        .select('score');

      const totalScore = totalScoreData.reduce((sum, entry) => sum + entry.score, 0);
      setTotalScoreAll(totalScore);
    } catch (error) {
      console.error('Error fetching rank or total score:', error.message);
    }
  };

  const submitScoreToSupabase = async (newScore) => {
    if (!userId) {
      console.error("User ID is not set. Cannot submit score.");
      return;
    }
    try {
      const { data: existingEntry } = await supabase
        .from('leaderboard')
        .select('id')
        .eq('user_id', userId)
        .single();

      if (existingEntry) {
        await supabase
          .from('leaderboard')
          .update({ score: newScore, username })
          .eq('id', existingEntry.id);
      } else {
        await supabase.from('leaderboard').insert([{ user_id: userId, username, score: newScore }]);
      }
      fetchRankAndTotalScore();
    } catch (error) {
      console.error('Error submitting score:', error.message);
    }
  };

  // Load the Phaser game on component mount
  useEffect(() => {
    const gameWidth = window.innerWidth;
    const gameHeight = window.innerHeight - 104; // Adjust for AppBar (64px) and Footer (40px)
    const scaleFactor = Math.min(gameWidth / 800, gameHeight / 600);

    const config = {
      type: Phaser.AUTO,
      width: gameWidth,
      height: gameHeight,
      parent: gameContainerRef.current,
      scene: {
        preload,
        create,
        update,
      },
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
        },
      },
      scale: {
        mode: Phaser.Scale.RESIZE,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
    };

    const game = new Phaser.Game(config);
    let hat;
    const targetPosition = { x: gameWidth / 2, y: gameHeight / 2 };
    const speed = 500;

    function preload() {
      this.load.image('kitten', '/catnohat.png');
      this.load.image('hat', '/hat.png');
    }

    function create() {
      this.add.image(targetPosition.x, targetPosition.y, 'kitten').setScale(scaleFactor);
      spawnHat.call(this);
      this.input.on('pointerdown', () => stopHat.call(this));
    }

    function spawnHat() {
      const spawnPositions = [
        { x: -50, y: gameHeight / 2 },
        { x: gameWidth + 50, y: gameHeight / 2 },
        { x: gameWidth / 2, y: -50 },
        { x: gameWidth / 2, y: gameHeight + 50 },
        { x: -50, y: -50 },
        { x: gameWidth + 50, y: -50 },
        { x: -50, y: gameHeight + 50 },
        { x: gameWidth + 50, y: gameHeight + 50 },
      ];
      const spawnPosition = Phaser.Utils.Array.GetRandom(spawnPositions);

      hat = this.physics.add.image(spawnPosition.x, spawnPosition.y, 'hat').setScale(scaleFactor);
      this.physics.moveTo(hat, targetPosition.x, targetPosition.y, speed);
    }

    function stopHat() {
        if (hat) {
          hat.body.setVelocity(0);
          const distance = Phaser.Math.Distance.Between(hat.x, hat.y, targetPosition.x, targetPosition.y);
          let points = 0;
      
          if (distance <= 2) {
            points = 250;
          } else if (distance <= 5) {
            points = 100;
          } else if (distance <= 20) {
            points = 50;
          } else {
            // Deduct 30 points if the user clicks and misses
            points = -30;
            showPointAlert(`-${Math.abs(points)}`);
          }
      
          setScore((prevScore) => {
            const newScore = prevScore + points;
            submitScoreToSupabase(newScore);
            return newScore;
          });
      
          if (points > 0) {
            showPointAlert(`+${points}`);
          }
      
          this.time.delayedCall(1000, () => {
            hat.destroy();
            spawnHat.call(this);
          });
        }
      }
      

    function update() {
      if (hat) {
        const buffer = 100;
        const gameWidth = this.sys.game.config.width;
        const gameHeight = this.sys.game.config.height;

        if (
          hat.x < -buffer ||
          hat.x > gameWidth + buffer ||
          hat.y < -buffer ||
          hat.y > gameHeight + buffer
        ) {
          hat.destroy();
          spawnHat.call(this);
        }
      }
    }

    return () => {
      game.destroy(true);
    };
  }, []);

  const showPointAlert = (points) => {
    const color = points < 0 ? 'red' : 'green';
    setPointAlert({ text: points > 0 ? `${points}` : `${points}`, color });
    setTimeout(() => setPointAlert(null), 1000);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleUsernameSubmit = async () => {
    if (!username) {
      setUsernameError('Username cannot be empty');
      return;
    }

    const { data: existingUsernames, error } = await supabase
      .from('leaderboard')
      .select('username')
      .eq('username', username)
      .single();

    if (error && error.code !== 'PGRST116') {
      console.error('Error checking username:', error.message);
    } else if (existingUsernames) {
      setUsernameError('This username is already taken. Please choose another.');
    } else {
      setUsernameError('');
      localStorage.setItem('username', username);
      setIsModalOpen(false);
      submitScoreToSupabase(score);
    }
  };

  const generateTweet = () => {
    const tweetText = `My current score is ${score} and I'm ranked ${userRank} on the $KWIF "Hat stays on" game!\n\nThe global score is ${totalScoreAll}.\n\nCan you beat my score?\n\nPlay now: https://www.kwifgames.xyz/\n\nBe sure to follow the official @kwif_on_sol account.`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, '_blank');
  };
  const generateImageWithScore = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas element not found');
      return;
    }
  
    const context = canvas.getContext('2d');
    if (!context) {
      console.error('Failed to get canvas context');
      return;
    }
  
    const imageUrl = '/post_2.png'; // Replace with the path to your image
  
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
  
      context.drawImage(image, 0, 0);
  
// Set a retro-style font and color for text
context.font = '25px "Press Start 2P", monospace'; // Slightly larger retro font for the title
context.fillStyle = '#FF00FF'; // Neon pink for contrast
context.textAlign = 'left'; // Center-align text

// Center title text at the top
context.fillText('$KWIF ', canvas.width / 2, canvas.height - 100);

// Adjust positions for other information
context.font = '16px "Press Start 2P", monospace'; // Smaller font for details

context.fillText(`"THE HAT STAYS ON" GAME`, canvas.width / 2, canvas.height - 80);

   
  
      const downloadLink = document.createElement('a');
      downloadLink.download = 'game_score.png';
      downloadLink.href = canvas.toDataURL('image/png');
      downloadLink.click();
    };
  };
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
     <AppBar position="fixed" sx={{ backgroundColor: '#663399' }}>
    <Toolbar
      sx={{
        display: 'flex',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Typography
        variant="h6"
        component="div"
        sx={{
          flexGrow: 1,
          fontSize: { xs: '1rem', sm: '1.5rem' },
          textAlign: { xs: 'center', sm: 'left' },
          minWidth: { sm: '120px' }, // Ensures space for title on larger screens
        }}
      >
        $KWIF
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{
          fontSize: { xs: '0.75rem', sm: '1rem' },
          textAlign: 'center',
          flexGrow: 1,
          minWidth: { sm: '300px' }, // Ensures space for scores on larger screens
          order: { xs: 3, sm: 2 }, // Moves this to below buttons on smaller screens
          mb: { xs: 1, sm: 0 },
        }}
      >
        Rank: {userRank || '-'} | Your Score: {score} | Total Score: {totalScoreAll}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 1, sm: 2 },
          justifyContent: { xs: 'center', sm: 'flex-end' },
          order: { xs: 2, sm: 3 }, // Keeps buttons together and on the right on larger screens
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={generateTweet}
          sx={{
            fontSize: { xs: '0.75rem', sm: '1rem' },
            padding: { xs: '4px 8px', sm: '6px 12px' },
          }}
        >
          share score
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={generateImageWithScore}
          sx={{
            fontSize: { xs: '0.75rem', sm: '1rem' },
            padding: { xs: '4px 8px', sm: '6px 12px' },
          }}
        >
          score card
        </Button>
      </Box>
    </Toolbar>
  </AppBar>

      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem>
            <ListItemText primary="Leaderboard" />
          </ListItem>
          <LeaderboardGame score={score} />
        </List>
      </Drawer>
      <div
  style={{
    color: 'white',
    fontSize: '2rem',
    position: 'fixed',
    top: 100,
    left: '50%',
    transform: 'translateX(-50%)',
    fontWeight: 'bold',
    transition: 'opacity 0.5s ease-in-out',
  }}
  sx={{
    display: { xs: 'none', sm: 'block' }, // Hidden on mobile, shown on small and larger screens
  }}
>
  The Hat Stays on!
</div>

{pointAlert && (
  <div style={{
    color: pointAlert.color,
    fontSize: '2rem',
    position: 'fixed',
    top: 200,
    left: '50%',
    transform: 'translateX(-50%)',
    fontWeight: 'bold',
    transition: 'opacity 0.5s ease-in-out',
    opacity: pointAlert ? 1 : 0,
  }}>
    {pointAlert.text}
  </div>
)}
      <Box
        ref={gameContainerRef}
        sx={{
          flexGrow: 1,
          marginTop: '50px',
          marginBottom: '40px',
          overflow: 'hidden',
        }}
      >
        {/* Game canvas content */}
      </Box>
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      <Footer />

      <Modal open={isModalOpen} onClose={() => { }} aria-labelledby="username-modal-title" aria-describedby="username-modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}>
          <Typography id="username-modal-title" variant="h6" component="h2">
            Enter Your Username
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setUsernameError('');
            }}
            error={!!usernameError}
            helperText={usernameError}
          />
          <Button variant="contained" color="primary" onClick={handleUsernameSubmit}>
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Game;
