// src/Leaderboard.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    // Fetch the initial leaderboard data
    fetchLeaderboard();

    // Create a channel for real-time updates
    const leaderboardChannel = supabase
      .channel('leaderboard_channel')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'leaderboard' }, payload => {
        setLeaderboard(prev => [...prev, payload.new]);
      })
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'leaderboard' }, payload => {
        setLeaderboard(prev => 
          prev.map(item => (item.id === payload.new.id ? payload.new : item))
        );
      })
      .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'leaderboard' }, payload => {
        setLeaderboard(prev => prev.filter(item => item.id !== payload.old.id));
      })
      .subscribe();

    // Cleanup on component unmount
    return () => {
      supabase.removeChannel(leaderboardChannel);
    };
  }, []);

  const fetchLeaderboard = async () => {
    const { data, error } = await supabase
      .from('leaderboard')
      .select('*')
      .order('score', { ascending: false })
      .limit(50); // Fetch top 50 scores

    if (error) console.error('Error fetching leaderboard:', error);
    else setLeaderboard(data);
  };

  return (
    <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px', border: '1px solid #ddd' }}>
   
      <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
        {leaderboard.map((entry, index) => (
          <li key={entry.id} style={{ marginBottom: '8px' }}>
            {entry.username}: {entry.score} points
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Leaderboard;
