import logo from './logo.svg';
import './App.css';
import Game from './components/Game';
import MetaTags from './components/MetaTags'; // Import the MetaTags component
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
    

 
       <Game />
     
    </div>
  );
}

export default App;
